/* eslint-disable max-lines */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { AppImages, React } from '@/app'
import { View, Text, CenterWrapper, Link, Icon, Image } from '@/components'
import { variantProvider } from '@/app/theme'

type FooterProps = any

type FooterLinksProps = {
  text?: string
  to?: string
}

const footerLinks: FooterLinksProps[] = [
  { text: 'Terms of Use', to: '/terms-and-conditions' },
  { text: 'Privacy Policy', to: '/privacy-policy' },
]

export const Footer: React.FC<FooterProps> = () => {
  return (
    <View
      css={styles.wrapper}
      variants={['relative', 'fullHeight', 'justifyCenter']}
    >
      <CenterWrapper variants={['paddingVertical:8', 'column', 'justifyStart', 'alignStart']}>
        <View variants={['fullWidth', 'justifySpaceBetween']} responsiveVariants={{ small: ['column', 'center'] }}>
          <View variants={['justifyCenter', 'alignCenter']} down={'small'}>
            <Image
              down={'small'}
              objectFit='contain'
              source={AppImages.RedTransparentLogo}
              css={{ width: 100, height: 100 }}
            />
          </View>
          <View variants={['gap:4']} responsiveVariants={{ small: ['column', 'marginBottom:5'] }}>
            <View variants={['column', 'gap:1']}>
              <Text responsiveVariants={{ small: ['textCenter'] }} variants={['black700']} text={'Terms & Conditions'} />
              {footerLinks.map(el => <Link responsiveVariants={{ small: ['textCenter'] }} variants={['primary', 'bold']} key={el?.text} text={el?.text} to={el?.to} />)}
            </View>
          </View>
          <View variants={['column', 'gap:1']}>
            <Text variants={['black700']} responsiveVariants={{ small: ['textCenter'] }} text={'customerservices@cherrydating.co.uk'} />
            <Text variants={['black700']} responsiveVariants={{ small: ['textCenter'] }} text={'©2023 Cherry Dating Ltd'} />
            <Text variants={['black700']} responsiveVariants={{ small: ['textCenter'] }} text={'Made with ♥️  in London, UK'} />
          </View>
        </View>
        <View variants={['marginVertical:4']} style={styles.separator} />
        <View
          variants={['row', 'justifySpaceBetween', 'fullWidth']}
          responsiveVariants={{ small: ['column', 'center'] }}
        >
          <View variants={['column', 'gap:1']}>
            <Text
              responsiveVariants={{ small: ['textCenter'] }}
              variants={['black400']}
              text={'Cherry Dating Ltd. is registered in England & Wales, company no. 13734947.'}
            />
            <Text
              responsiveVariants={{ small: ['textCenter'] }}
              variants={['black400']}
              text={'This website uses third-party assets and software. By using our website you agree to the terms of use.'}
            />
          </View>
          <View
            variants={['gap:2']}
            responsiveVariants={{ small: ['marginTop:3'] }}
          >
            <Link to={'https://www.facebook.com/people/Cherry-Dating/61550739111031/'}>
              <Icon name={'facebook'} />
            </Link>
            <Link to={'https://www.instagram.com/cherry_datinguk/?hl=it'}>
              <Icon name={'instagram'} />
            </Link>
          </View>
        </View>
      </CenterWrapper>
    </View>

  )
}

const SEPARATOR_HEIGHT: Number = 2

const styles = variantProvider.createComponentStyle(
  (theme) => ({
    wrapper: {

    },
    separator: {
      backgroundColor: theme.colors.lightPrimary,
      height: SEPARATOR_HEIGHT,
      ...theme.presets.fullWidth,
    },

  }),
  true,
)
